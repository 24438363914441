import React, { useState, useEffect } from 'react';
import { DefaultBasePage } from '@components';
import { useNavigate } from 'react-router-dom';
import { Box, Stack } from '@mui/material';
import { Link, useSnackbar } from '@fdha/web-ui-library';
import { useGetProfileQuery } from '@fdha/graphql-api-patient';
import { signOut } from '@aws-amplify/auth';
import { handleInviteFriends } from '@utils';
import { useFeatureFlag, useSystemSetting } from '@fdha/common-hooks';
import { TermsAndConditionsUtils } from '@fdha/web-auth';

import ProfileCard from './ProfileCard';

const Profile = () => {
  const navigate = useNavigate();
  const { showSnackbarV2 } = useSnackbar();

  const { isFeatureEnabled } = useFeatureFlag();
  const showDocuments = isFeatureEnabled('show_documents');

  const { value: isOtpEnabled } = useSystemSetting<boolean>('isOtpEnabled', {
    allowAutomaticRefetch: false,
  });

  const { data } = useGetProfileQuery();

  const [terms, setTerms] = useState('');

  const handleLogout = async () => {
    try {
      await signOut();
      navigate('/');
    } catch (error) {
      showSnackbarV2({
        message: 'Error to log out',
        severity: 'error',
        i18nKey: 'profile:snackbar.errorLogOut',
      });
    }
  };

  useEffect(() => {
    let isMounted = true;

    const getTerms = async () => {
      const termsLink = await TermsAndConditionsUtils.getTermsLink();
      if (isMounted) {
        setTerms(termsLink);
      }
    };
    if (!terms) {
      getTerms();
    }

    return () => {
      isMounted = false;
    };
  }, [terms]);

  return (
    <DefaultBasePage
      i18nKeyTitle="profile:title"
      title="Profile"
      contentSize="small"
    >
      <>
        <Stack spacing={2}>
          <ProfileCard
            showLeftPicture
            id="yourAccount"
            i18nKey="profile:yourAccount.title"
            text="Your Account"
            picture={data?.me.picture}
            userName={data?.me.name}
            handleClick={() => navigate('/profile/your-account')}
          />
          {showDocuments && (
            <ProfileCard
              id="documents"
              i18nKey="profile:documents.title"
              text="Documents"
              handleClick={() => navigate('/profile/documents')}
            />
          )}
          <ProfileCard
            id="medicalInformation"
            i18nKey="profile:medicalInformation.title"
            text="Medical Information"
            handleClick={() => navigate('/profile/medical-information')}
          />
          <ProfileCard
            id="notifications"
            i18nKey="profile:notifications.title"
            text="Notifications"
            leftIcon="bell-outline"
            handleClick={() => navigate('/profile/notifications')}
          />
          <ProfileCard
            id="help"
            i18nKey="profile:help.title"
            text="Help"
            leftIcon="question-mark-circle-outline"
            handleClick={() => navigate('/profile/help')}
          />
          {!isOtpEnabled && (
            <ProfileCard
              id="changePassword"
              i18nKey="profile:changePassword.title"
              text="Change Password"
              handleClick={() => navigate('/profile/change-password')}
            />
          )}
          <ProfileCard
            id="inviteFriends"
            i18nKey="profile:inviteFriends.title"
            text="Invite Friends"
            rightIcon="person-add-outline"
            handleClick={() => handleInviteFriends(showSnackbarV2)}
          />
          <ProfileCard
            id="logout"
            i18nKey="profile:logout"
            text="Log Out"
            rightIcon="log-out-outline"
            handleClick={handleLogout}
          />
        </Stack>
        <Box mt={3}>
          <Link href={terms} target="_blank" i18nKey="profile:termsOfUse">
            Access Terms of Use
          </Link>
        </Box>
      </>
    </DefaultBasePage>
  );
};

export default Profile;
