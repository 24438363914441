import React from 'react';
import { Box, Link } from '@mui/material';

import { Typography } from '../Typography/Typography';

export interface SupportCardProps {
  email?: string;
  emailSubject?: string;
}

export const SupportCard: React.FC<SupportCardProps> = ({
  emailSubject,
  email = 'support@faeththerapeutics.com',
}) => {
  const baseUrl = `mailto:${email}`;
  const subject = emailSubject ? `?subject=${emailSubject}` : '';
  const href = `${baseUrl}${subject}`;

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Typography i18nKey="temporary:common.support">
        Need support? Please reach out to:
      </Typography>
      <Link href={href} target="_blank">
        {email}
      </Link>
    </Box>
  );
};
