import React, { FC } from 'react';
import {
  ActivityFrequency,
  Goal,
  GoalInput,
  GoalUpdateInput,
  useAddGoalMutation,
  useUnscheduleGoalMutation,
  useUpdateGoalMutation,
} from '@fdha/graphql-api-patient';
import { Stack } from '@mui/material';
import {
  buildDateTime,
  ActivityFormActions,
  GoalSchema,
  goalValidationSchema,
  useSnackbar,
  useDialog,
  ActivityKind,
  formatDate,
} from '@fdha/web-ui-library';
import { Form, Formik } from 'formik';
import { pick } from 'lodash';
import { ApolloCache } from '@apollo/client';
import { useAnalytics } from '@fdha/common-hooks';
import { getTranslatedErrorMessage } from '@fdha/common-utils';

import ActivityFormFields from '../Forms/ActivityFormFields';
import { getSchemaInitialValues } from '../Forms/schema';

import GoalSteps from './GoalSteps';

const buildCreateGoalPayload = (values: GoalSchema): GoalInput => {
  return {
    title: values.title,
    description: values.description,
    reminder: values.reminder,
    steps: values.steps.filter((s) => s.title),
    time: buildDateTime(formatDate(values.date), values.time, values.dayPeriod),
    frequency: ActivityFrequency.Once,
  };
};

const buildUpdateGoalPayload = (
  initialValues: GoalSchema,
  values: GoalSchema
): GoalUpdateInput => {
  const time = buildDateTime(
    formatDate(values.date),
    values.time,
    values.dayPeriod
  );
  const initialTime = buildDateTime(
    formatDate(initialValues.date),
    initialValues.time,
    initialValues.dayPeriod
  );

  return {
    title: values.title,
    description: values.description,
    reminder:
      values.reminder !== initialValues.reminder ? values.reminder : undefined,
    time: time !== initialTime ? time : undefined,
    steps: values.steps.filter((s) => s.title),
  };
};

interface Props {
  data?: Goal;
  onCancel: () => void;
}

const AddOrEditGoal: FC<Props> = ({ data, onCancel }) => {
  const requiredMessage = getTranslatedErrorMessage('required', 'web');
  const validDateMessage = getTranslatedErrorMessage('validDate', 'web');
  const validTimeMessage = getTranslatedErrorMessage('validTime', 'web');
  const endDateAfterStartMessage = getTranslatedErrorMessage(
    'endDateAfterStartDate',
    'web'
  );
  const endDateFutureMessage = getTranslatedErrorMessage(
    'endDateFuture',
    'web'
  );
  const isEditing = !!data;
  const [addGoal] = useAddGoalMutation();
  const [unscheduleGoal] = useUnscheduleGoalMutation();
  const [updateGoal] = useUpdateGoalMutation();
  const { showSnackbarV2 } = useSnackbar();
  const { closeDialog } = useDialog();
  const { analyticsClient } = useAnalytics();

  const emptyStep = { title: '' };

  const initialValues: GoalSchema = {
    ...pick(getSchemaInitialValues(data), [
      'title',
      'description',
      'reminder',
      'date',
      'time',
      'dayPeriod',
      'frequency',
    ]),
    steps: data?.steps
      ? [...data.steps.map((s) => ({ title: s.title })), emptyStep]
      : [emptyStep],
  };

  const updateCache = (cache: ApolloCache<any>) => {
    cache.evict({ fieldName: 'activitiesInRange' });
    cache.gc();
  };

  const saveGoal = async (values: GoalSchema) => {
    if (data) {
      const payload = buildUpdateGoalPayload(initialValues, values);
      try {
        await updateGoal({
          variables: { id: data.id, props: payload },
          update(cache) {
            updateCache(cache);
          },
        });
        showSnackbarV2({
          message: 'Changes Saved',
          severity: 'success',
          i18nKey: 'common:snackbar.changesSaved',
        });
      } catch (error) {
        showSnackbarV2({
          message: 'Error to Update Goal',
          severity: 'error',
          i18nKey: 'activities:addOrEdit.snackbar.goal.errorUpdate',
        });
      }
    } else {
      const payload = buildCreateGoalPayload(values);
      try {
        const createdGoal = await addGoal({
          variables: { props: payload },
          update(cache) {
            updateCache(cache);
          },
        });
        analyticsClient?.track('Activity Created', {
          id: createdGoal.data?.addGoal.id,
          type: ActivityKind.Goal,
        });
        showSnackbarV2({
          message: 'Goal Created',
          severity: 'success',
          i18nKey: 'activities:addOrEdit.snackbar.goal.created',
        });
      } catch (error) {
        showSnackbarV2({
          message: 'Error to Create Goal',
          severity: 'error',
          i18nKey: 'activities:addOrEdit.snackbar.goal.errorCreate',
        });
      }
    }
    closeDialog();
  };

  const handleUnschedule = async () => {
    if (!data) {
      return;
    }

    try {
      await unscheduleGoal({
        variables: { id: data.id },
        update(cache) {
          updateCache(cache);
        },
      });
      showSnackbarV2({
        message: 'Goal Unscheduled',
        severity: 'success',
        i18nKey: 'activities:addOrEdit.snackbar.goal.unscheduled',
      });
    } catch (error) {
      showSnackbarV2({
        message: 'Unable to Unschedule Goal',
        severity: 'error',
        i18nKey: 'activities:addOrEdit.snackbar.goal.unableUnschedule',
      });
    }
    closeDialog();
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={goalValidationSchema({
          required: requiredMessage,
          date: validDateMessage,
          time: validTimeMessage,
          future: endDateFutureMessage,
          afterStart: endDateAfterStartMessage,
        })}
        onSubmit={saveGoal}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <ActivityFormFields>
                <ActivityFormFields.Title />
                <ActivityFormFields.Details />
                <GoalSteps />
                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  columnGap={2}
                >
                  <ActivityFormFields.DateInput />
                  <ActivityFormFields.Time />
                </Stack>

                <ActivityFormFields.Reminder />
              </ActivityFormFields>
              <ActivityFormActions
                isEditing={isEditing}
                onUnschedule={handleUnschedule}
                disableSave={isSubmitting}
                onCancel={onCancel}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddOrEditGoal;
