import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Courses from './Courses';

const CoursesWrapper: React.FC = () => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(search);
  const courseId = searchParams.get('id');
  const contentId = searchParams.get('contentId');

  useEffect(() => {
    if (!courseId) {
      return;
    }

    if (!contentId) {
      return navigate(`${pathname}/${courseId}`, { replace: true });
    }

    navigate(`${pathname}/${courseId}/${contentId}`, { replace: true });
  }, [pathname, courseId, contentId, navigate]);

  return <Courses />;
};

export default CoursesWrapper;
