import React from 'react';
import { MuiOtpInput, MuiOtpInputProps } from 'mui-one-time-password-input';

import { InputError } from '../InputError/InputError';

export interface OtpInputProps extends MuiOtpInputProps {
  errorMessage?: string;
}

export const OtpInput: React.FC<OtpInputProps> = ({
  errorMessage,
  ...otpProps
}) => {
  const length = otpProps.length || 6;
  const validateChar = (char: string) => /^\d$/.test(char);

  return (
    <>
      <MuiOtpInput
        autoFocus
        length={length}
        validateChar={validateChar}
        {...otpProps}
      />
      {errorMessage && <InputError error={errorMessage} v2={true} />}
    </>
  );
};
