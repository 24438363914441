import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { ImageBasePage } from '@components';
import { Box } from '@mui/material';
import {
  Button,
  SupportCard,
  Typography,
  OtpInput,
  Countdown,
} from '@fdha/web-ui-library';
import * as Yup from 'yup';
import {
  addOtpValidation,
  convertPhoneToE164US,
  OtpDestination,
  requestOTP,
  getTranslatedErrorMessage,
} from '@fdha/common-utils';
import { FormikHelpers, useFormik } from 'formik';
import { useEffectOnce } from '@fdha/common-hooks';
import { capitalize } from 'lodash';
import { useLocalStorage } from '@hooks';
import { confirmSignIn, fetchUserAttributes } from '@aws-amplify/auth';

export type DeliveryType = 'email' | 'phone';

export interface CodeSchema {
  code: string;
}

interface DeliveryData {
  inputType: string;
  value: string;
}

interface OtpCodeProps {
  deliveryData: DeliveryData;
  initialDeliveryType: DeliveryType;
  setStep: Dispatch<SetStateAction<'login' | 'otp'>>;
}

const validationSchema = Yup.object().shape({
  code: addOtpValidation({
    systemType: 'web',
    hideErrorMessage: true,
  }).required(''),
});

const initialValues: CodeSchema = {
  code: '',
};

const timeInSeconds = 50;

const OtpCode: React.FC<OtpCodeProps> = ({
  setStep,
  deliveryData,
  initialDeliveryType,
}) => {
  const [isRetryEnabled, setIsRetryEnabled] = useState(false);
  const [deliveryType, setDeliveryType] = useState(initialDeliveryType);
  const { saveToLocalStorage } = useLocalStorage('me', false);

  const codeVia = deliveryType === 'phone' ? 'email' : 'phone';
  const deliveryAddress = deliveryData.value;

  const deliveryAddressFormatted = useRef(
    deliveryData.inputType === 'email'
      ? deliveryData.value
      : convertPhoneToE164US(deliveryData.value)
  );

  useEffectOnce(() => {
    handleOtpRequest(deliveryType);
  });

  const handleOtpRequest = async (type: DeliveryType) => {
    const otpDestination =
      type === 'email' ? OtpDestination.Email : OtpDestination.SMS;

    try {
      await requestOTP(deliveryAddressFormatted.current, otpDestination);
    } catch (error) {
      console.log('Error on OTP request', error);
    }
  };

  const handleOtpSubmit = async (
    values: CodeSchema,
    helpers: FormikHelpers<CodeSchema>
  ) => {
    let output;

    try {
      output = await confirmSignIn({ challengeResponse: values.code });
      const user = await fetchUserAttributes();

      saveToLocalStorage(
        {
          isAuthenticated: true,
          lastLogin: new Date(),
        },
        false,
        `pat_${user['custom:migrated_sub'] || user.sub}`
      );
    } catch (err) {
      console.log('Error on OTP validation', err);
    } finally {
      if (!output?.isSignedIn) {
        helpers.setErrors({
          code: getTranslatedErrorMessage('validOtpCode', 'web'),
        });
      }
    }
  };

  const handleRetry = (type: DeliveryType) => {
    setIsRetryEnabled(false);
    setFieldValue('code', '');

    handleOtpRequest(type);
  };

  const toggleDeliveryType = () => {
    const newDeliveryType = deliveryType === 'email' ? 'phone' : 'email';
    setDeliveryType(newDeliveryType);

    handleRetry(newDeliveryType);
  };

  const helperButtons = [
    {
      i18n: {
        key: 'temporary:otpLogin.code.resend',
        fallback: 'Resend Code',
      },
      onClick: () => handleRetry(deliveryType),
    },
    {
      i18n: {
        key: 'temporary:otpLogin.code.reEnterData',
        fallback: 'Re-Enter Phone Or Email',
      },
      onClick: () => setStep('login'),
    },
    {
      i18n: {
        key: 'temporary:otpLogin.code.codeViaDeliveryType',
        fallback: `Get Code via ${deliveryType}`,
        params: { deliveryType: capitalize(codeVia) },
      },
      onClick: () => toggleDeliveryType(),
    },
  ];

  const {
    handleChange,
    setFieldValue,
    submitForm,
    values,
    isValid,
    isSubmitting,
    errors,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    validateOnChange: true,
    onSubmit: (formValues, helpers) => handleOtpSubmit(formValues, helpers),
  });

  return (
    <ImageBasePage
      image={{
        url: '/images/woman_02.png',
        altText: 'Old Woman',
      }}
      i18nKeyTitle="temporary:otpLogin.common.loginTitle"
      i18nKeySubtitle="temporary:otpLogin.code.description"
      i18nParamsSubtitle={{ deliveryType, deliveryAddress }}
      alignHeader="center"
      alignSubtitle="center"
      title="Log into your account"
      subtitle={`You'll receive a code on your ${deliveryType} if we find an account linked to “${deliveryAddress}”`}
      contentSize="small"
    >
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box px={6}>
          <OtpInput
            onChange={handleChange('code')}
            value={values.code}
            errorMessage={errors.code}
          />
          <Button
            i18nKey="common:button.continue"
            type="submit"
            variant="contained"
            size="large"
            sx={{ mt: 2 }}
            fullWidth
            disabled={!isValid || isSubmitting}
            onClick={submitForm}
          >
            Continue
          </Button>
        </Box>
        <Typography
          i18nKey="temporary:otpLogin.code.didntReceiveCode"
          textAlign="center"
          mt={8}
        >
          "Didn’t receive your code?"
        </Typography>
        {!isRetryEnabled && (
          <Countdown
            timeInSeconds={timeInSeconds}
            onTimeReached={() => setIsRetryEnabled(true)}
          />
        )}
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="60%"
          gap={2}
          marginY={2}
        >
          {helperButtons.map((button, index) => (
            <Button
              fullWidth
              key={index}
              onClick={button.onClick}
              variant="outlined"
              size="large"
              i18n={button.i18n}
              disabled={!isRetryEnabled}
            />
          ))}
        </Box>
        <SupportCard emailSubject="[Support Request] Faeth App Login" />
      </Box>
    </ImageBasePage>
  );
};

export default OtpCode;
