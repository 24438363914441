import { DefaultBasePage } from '@components';
import {
  Button,
  AutocompleteV2,
  getCancerType,
  mainGoalList,
  Option,
  otherMedicalConditionsList,
  SelectV2,
  states,
  TextFieldV2,
  useSnackbar,
  getCancersTypesWithI18n,
  getCurrentTreatmentsWithI18n,
  getCaregiversWithI18n,
  DatePickerV2,
  formatDate,
} from '@fdha/web-ui-library';
import {
  GetProfileDocument,
  MainGoalType,
  useGetProfileQuery,
  UserType,
  useUpdateProfileMutation,
} from '@fdha/graphql-api-patient';
import { Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FormikErrors, FormikTouched, useFormik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { usePrompt } from '@hooks';
import { getTranslatedErrorMessage } from '@fdha/common-utils';
import { utcToZonedTime } from 'date-fns-tz';

interface MedicalInformationProps {
  cancerDiag: Option | null;
  additionalInfo: string;
  diagDate: Date | null;
  dateDetails: string;
  currentTreatment: string[];
  oncologistName: string;
  facilityName: string;
  facilityCity: string;
  facilityState: string;
  medication: string;
  motivation: string;
  primaryCaregiver: string;
  primaryCaregiverDesc: string;
  primaryCaregiverName: string;
  primaryCheerleader: string;
  primaryCheerleaderDesc: string;
  primaryCheerleaderName: string;
  otherMedicalConditions: string[];
  mainGoal: string;
}

const MedicalInformation = () => {
  const navigate = useNavigate();
  const { showSnackbarV2 } = useSnackbar();

  const { data } = useGetProfileQuery();
  const [updateProfile] = useUpdateProfileMutation();

  const validationSchema = Yup.object().shape({
    diagDate: Yup.date()
      .nullable(true)
      .typeError(getTranslatedErrorMessage('validDate', 'web')),
  });

  const notCtValidationSchema = validationSchema.concat(
    Yup.object().shape({
      mainGoal: Yup.string().required(
        getTranslatedErrorMessage('required', 'web')
      ),
    })
  );

  const isCt = data?.me.type === UserType.ClinicalTrialPatient;

  const initialValues: MedicalInformationProps = {
    cancerDiag: getCancerType(data?.me.cancer_type) || null,
    additionalInfo: data?.me.diag_details || '',
    diagDate: data?.me.diag_date
      ? new Date(utcToZonedTime(data.me.diag_date, 'UTC'))
      : null,
    dateDetails: data?.me.diag_date_desc || '',
    currentTreatment: data?.me.current_treatment || [],
    oncologistName: data?.me.oncologist_name || '',
    facilityName: data?.me.facility?.name || '',
    facilityCity: data?.me.facility?.city || '',
    facilityState: data?.me.facility?.state || '',
    medication: data?.me.medication || '',
    motivation: data?.me.motivation || '',
    primaryCaregiver: data?.me.caregiver?.primary_caregiver || '',
    primaryCaregiverDesc: data?.me.caregiver?.primary_caregiver_desc || '',
    primaryCaregiverName: data?.me.caregiver?.name || '',
    primaryCheerleader: data?.me.cheerleader?.primary_cheerleader || '',
    primaryCheerleaderDesc:
      data?.me.cheerleader?.primary_cheerleader_desc || '',
    primaryCheerleaderName: data?.me.cheerleader?.name || '',
    otherMedicalConditions: data?.me.other_medical_conditions || [],
    mainGoal: data?.me.main_goal || '',
  };

  const handleErrors = (
    errors: FormikErrors<MedicalInformationProps>,
    touched: FormikTouched<MedicalInformationProps>
  ) => {
    return {
      diagDate: touched.diagDate && errors.diagDate,
      mainGoal: touched.mainGoal && errors.mainGoal,
    };
  };

  const handleCancel = () => {
    showSnackbarV2({
      message: 'Changes Not Saved',
      severity: 'info',
      i18nKey: 'common:snackbar.changesNotSaved',
    });
    navigate('/profile', { replace: true });
  };

  const handleSubmit = async (values: MedicalInformationProps) => {
    try {
      await updateProfile({
        variables: {
          props: {
            cancer_type:
              values.cancerDiag?.id === 'other'
                ? undefined
                : values.cancerDiag?.id,
            diag_details: values.additionalInfo,
            diag_date: formatDate(values.diagDate, 'monthDayYear') || undefined,
            current_treatment: values.currentTreatment,
            other_medical_conditions: values.otherMedicalConditions,
            oncologist_name: values.oncologistName,
            facility: {
              name: values.facilityName,
              city: values.facilityCity,
              state: values.facilityState,
            },
            caregiver: {
              primary_caregiver: values.primaryCaregiver,
              primary_caregiver_desc:
                values.primaryCaregiver === 'other'
                  ? values.primaryCaregiverDesc
                  : null,
              name: values.primaryCaregiverName,
            },
            cheerleader: {
              primary_cheerleader: values.primaryCheerleader,
              primary_cheerleader_desc:
                values.primaryCheerleader === 'other'
                  ? values.primaryCheerleaderDesc
                  : null,
              name: values.primaryCheerleaderName,
            },
            motivation: values.motivation,
            medication: values.medication,
            main_goal: !isCt ? (values.mainGoal as MainGoalType) : null,
          },
        },
        refetchQueries: [GetProfileDocument],
      });

      showSnackbarV2({
        message: 'Changes Saved',
        severity: 'success',
        i18nKey: 'common:snackbar.changesSaved',
      });
    } catch (error) {
      console.error(error);
      showSnackbarV2({
        message: 'Error to update medical information',
        severity: 'error',
        i18nKey: 'profile:medicalInformation.snackbar.errorUpdateMedicalInfo',
      });
    }
  };

  const {
    errors,
    isSubmitting,
    values,
    touched,
    dirty,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit: FormikHandleSubmit,
  } = useFormik({
    initialValues,
    onSubmit: handleSubmit,
    validationSchema: isCt ? validationSchema : notCtValidationSchema,
    validateOnMount: true,
    enableReinitialize: true,
  });

  const error = handleErrors(errors, touched);

  usePrompt(dirty);

  return (
    <DefaultBasePage
      title="Medical Information"
      contentSize="small"
      i18nKeyTitle="profile:medicalInformation.title"
    >
      <Stack spacing={2}>
        <AutocompleteV2
          title="Cancer Diagnosis"
          options={getCancersTypesWithI18n()}
          value={values.cancerDiag}
          onChange={(_event, value) => setFieldValue('cancerDiag', value)}
          i18nKeyTitle="profile:medicalInformation.input.cancerDiagnosis.label"
        />
        <TextFieldV2
          multiline
          name="additionalInfo"
          title="Additional Information"
          value={values.additionalInfo}
          onChange={handleChange}
          i18nKeyTitle="profile:medicalInformation.input.additionalInformation.label"
        />
        <SelectV2
          multiple
          selectType="checkbox"
          name="otherMedicalConditions"
          title="Other Medical Conditions"
          options={otherMedicalConditionsList}
          value={values.otherMedicalConditions}
          onChange={handleChange}
          i18nKeyTitle="profile:medicalInformation.input.medicalConditions.label"
        />
        <DatePickerV2
          title="Diagnosis Date"
          value={values.diagDate}
          i18nKeyTitle="profile:medicalInformation.input.diagnosisDate.label"
          locale={data?.me?.language}
          onBlur={handleBlur}
          error={!!error.diagDate}
          helperText={error.diagDate}
          onChange={(value) => {
            setFieldValue('diagDate', value);
          }}
          views={['month', 'year']}
          format="MM/yyyy"
        />
        <TextFieldV2
          name="oncologistName"
          title="Oncologist Name"
          value={values.oncologistName}
          onChange={handleChange}
          i18nKeyTitle="profile:medicalInformation.input.oncologistName.label"
        />
        <TextFieldV2
          name="facilityName"
          title="Treatment Facility Name"
          value={values.facilityName}
          onChange={handleChange}
          i18nKeyTitle="profile:medicalInformation.input.facilityName.label"
        />
        <Stack spacing={2} direction="row">
          <TextFieldV2
            name="facilityCity"
            title="Facility City"
            value={values.facilityCity}
            onChange={handleChange}
            i18nKeyTitle="profile:medicalInformation.input.facilityCity.label"
          />
          <SelectV2
            fullWidth
            name="facilityState"
            title="Facility State"
            options={states}
            value={values.facilityState}
            onChange={handleChange}
            i18nKeyTitle="profile:medicalInformation.input.facilityState.label"
          />
        </Stack>
        <SelectV2
          multiple
          selectType="checkbox"
          name="currentTreatment"
          title="Current Treatment"
          options={getCurrentTreatmentsWithI18n()}
          value={values.currentTreatment}
          onChange={handleChange}
          i18nKeyTitle="profile:medicalInformation.input.currentTreatment.label"
        />
        <TextFieldV2
          multiline
          name="medication"
          title="Medication"
          value={values.medication}
          onChange={handleChange}
          i18nKeyTitle="profile:medicalInformation.input.medication.label"
        />
        <Stack spacing={2} direction="row">
          <SelectV2
            fullWidth
            name="primaryCaregiver"
            title="Primary Caregiver"
            options={getCaregiversWithI18n()}
            value={values.primaryCaregiver}
            onChange={handleChange}
            i18nKeyTitle="profile:medicalInformation.input.primaryCaregiver.label"
          />
          <TextFieldV2
            name="primaryCaregiverName"
            title="Primary Caregiver Name"
            value={values.primaryCaregiverName}
            onChange={handleChange}
            i18nKeyTitle="profile:medicalInformation.input.primaryCaregiverName.label"
          />
        </Stack>
        {values.primaryCaregiver === 'other' && (
          <TextFieldV2
            name="primaryCaregiverDesc"
            title="Primary Caregiver: Other"
            value={values.primaryCaregiverDesc}
            onChange={handleChange}
            i18nKeyTitle="profile:medicalInformation.input.primaryCaregiverOther.label"
          />
        )}
        <Stack spacing={2} direction="row">
          <SelectV2
            fullWidth
            name="primaryCheerleader"
            title="Primary Cheerleader"
            options={getCaregiversWithI18n()}
            value={values.primaryCheerleader}
            onChange={handleChange}
            i18nKeyTitle="profile:medicalInformation.input.primaryCheerleader.label"
          />
          <TextFieldV2
            name="primaryCheerleaderName"
            title="Primary Cheerleader Name"
            value={values.primaryCheerleaderName}
            onChange={handleChange}
            i18nKeyTitle="profile:medicalInformation.input.primaryCheerleaderName.label"
          />
        </Stack>
        {values.primaryCheerleader === 'other' && (
          <TextFieldV2
            name="primaryCheerleaderDesc"
            title="Primary Cheerleader: Other"
            value={values.primaryCheerleaderDesc}
            onChange={handleChange}
            i18nKeyTitle="profile:medicalInformation.input.primaryCheerleaderOther.label"
          />
        )}
        <TextFieldV2
          multiline
          name="motivation"
          title="Motivation"
          value={values.motivation}
          onChange={handleChange}
          i18nKeyTitle="profile:medicalInformation.input.motivation.label"
        />
        {!isCt && (
          <SelectV2
            name="mainGoal"
            title="Goal with the Faeth App"
            options={mainGoalList}
            value={values.mainGoal}
            onChange={handleChange}
            onBlur={handleBlur}
            error={!!error.mainGoal}
            helperText={error.mainGoal}
            i18nKeyTitle="profile:medicalInformation.input.goal.label"
          />
        )}
        <Stack spacing={2} direction="row" justifyContent="flex-end">
          <Button onClick={handleCancel} i18nKey="common:button.cancel">
            Cancel
          </Button>
          <Button
            type="submit"
            onClick={() => FormikHandleSubmit()}
            variant="contained"
            disabled={isSubmitting}
            sx={{ width: '140px' }}
            i18nKey="common:button.save"
          >
            Save
          </Button>
        </Stack>
      </Stack>
    </DefaultBasePage>
  );
};

export default MedicalInformation;
