import React from 'react';
import { ImageBasePage } from '@components';
import { LoginV2 } from '@fdha/web-auth';
import { useLocalStorage } from '@hooks';

const Login = () => {
  const { saveToLocalStorage } = useLocalStorage('me', false);

  const handleRememberMe = (rememberMe: boolean, userId: string) => {
    saveToLocalStorage(
      {
        rememberMe,
        isAuthenticated: true,
        lastLogin: new Date(),
      },
      false,
      `pat_${userId}`
    );
  };

  return (
    <ImageBasePage
      image={{
        url: '/images/woman_02.png',
        altText: 'Old Woman',
      }}
      i18nKeyTitle="login:title"
      i18nKeySubtitle="login:subtitle"
      title="Welcome back"
      titleVariant="h3"
      alignHeader="center"
      alignSubtitle="center"
      subtitle="Please enter your account information"
    >
      <LoginV2 handleRememberMe={handleRememberMe} isV2UserPool />
    </ImageBasePage>
  );
};

export default Login;
