import React, { Dispatch } from 'react';
import { ImageBasePage } from '@components';
import { Box, Link as MuiLink } from '@mui/material';
import {
  Button,
  MaskedTextFieldV2,
  SupportCard,
  TextFieldV2,
  ToggleButton,
  ToggleButtonOption,
  Typography,
} from '@fdha/web-ui-library';
import { useSystemSetting } from '@fdha/common-hooks';
import * as Yup from 'yup';
import {
  addPhoneNumberValidation,
  getFormikError,
  getTranslatedErrorMessage,
} from '@fdha/common-utils';
import { FormikHelpers, useFormik } from 'formik';

import { DeliveryType } from '../code/OtpCode';

const initialValues: LoginForm = {
  email: '',
  phone: '',
  inputType: 'phone',
};

const toggleOptions: ToggleButtonOption<string>[] = [
  {
    value: 'phone',
    label: 'Phone Number',
    i18nKey: 'temporary:otpLogin.toggleOptions.phone',
  },
  {
    value: 'email',
    label: 'Email',
    i18nKey: 'temporary:otpLogin.toggleOptions.email',
  },
];

export interface LoginForm {
  email: string;
  phone: string;
  inputType: DeliveryType;
}

interface OtpLoginProps {
  onSubmit: (values: LoginForm) => void;
  setInitialDeliveryType?: Dispatch<DeliveryType>;
}

const OtpLogin: React.FC<OtpLoginProps> = ({
  onSubmit,
  setInitialDeliveryType,
}) => {
  const requiredMessage = getTranslatedErrorMessage('required', 'web');

  const validationSchema = Yup.object().shape({
    email: Yup.string().when('inputType', {
      is: (inputType: string) => inputType === 'email',
      then: Yup.string()
        .email(getTranslatedErrorMessage('validEmail', 'web'))
        .required(requiredMessage),
    }),
    phone: Yup.string().when('inputType', {
      is: (inputType: string) => inputType === 'phone',
      then: addPhoneNumberValidation({
        systemType: 'web',
        translate: true,
      }).required(requiredMessage),
    }),
  });

  const { value: termsOfServiceLink } = useSystemSetting<string>(
    'termsOfServiceLink',
    {
      allowAutomaticRefetch: false,
    }
  );

  const { value: privacyPolicyLink } = useSystemSetting<string>(
    'privacyPolicyLink',
    {
      allowAutomaticRefetch: false,
    }
  );

  const { value: isOtpDebugEnabled } =
    useSystemSetting<boolean>('isOtpDebugEnabled');

  const handleContinue = (
    values: LoginForm,
    helpers: FormikHelpers<LoginForm>
  ) => {
    helpers.setTouched({});
    onSubmit(values);
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    submitForm,
    isSubmitting,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: (formValues, helpers) => handleContinue(formValues, helpers),
  });

  const getLink = (link: string) => <MuiLink href={link} target="_blank" />;

  const getError = (name: string) => {
    return getFormikError<LoginForm>(name, errors, touched);
  };

  const emailError = getError('email');
  const phoneError = getError('phone');

  const termsLink = getLink(termsOfServiceLink || '');
  const privacyLink = getLink(privacyPolicyLink || '');

  return (
    <ImageBasePage
      image={{
        url: '/images/woman_02.png',
        altText: 'Old Woman',
      }}
      i18nKeyTitle="temporary:otpLogin.nonCached.title"
      i18nKeySubtitle="temporary:otpLogin.nonCached.loginDescription"
      title="Welcome to the Faeth App"
      titleVariant="h4"
      alignHeader="center"
      alignSubtitle="center"
      subtitle="Please enter the phone number or email associated with your account"
      contentSize="small"
    >
      <Box
        height="100%"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box display="flex" justifyContent="center" flexDirection="column">
          <ToggleButton
            options={toggleOptions}
            setValue={(event) => setFieldValue('inputType', event)}
            selectedValue={values.inputType}
          />
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginY={4}
            px={4}
          >
            {values.inputType === 'phone' ? (
              <MaskedTextFieldV2
                name="phone"
                value={values.phone}
                error={!!phoneError}
                helperText={phoneError}
                onChange={handleChange('phone')}
                onBlur={handleBlur}
                mask="phoneNumber"
                size="medium"
                placeholder="xxx-xxx-xxxx"
                i18nKeyPlaceholder="profile:yourAccount.input.mobileNumber.placeholder"
              />
            ) : (
              <TextFieldV2
                name="email"
                value={values.email}
                onBlur={handleBlur}
                onChange={handleChange('email')}
                type="email"
                error={!!emailError}
                helperText={emailError}
                size="medium"
                placeholder="Enter your email"
                i18nKeyPlaceholder="temporary:otpLogin.nonCached.emailPlaceholder"
              />
            )}
            <Button
              fullWidth
              i18nKey="common:button.continue"
              type="submit"
              variant="contained"
              size="large"
              onClick={submitForm}
              disabled={isSubmitting}
              sx={{ mt: 2 }}
            >
              Continue
            </Button>
            {isOtpDebugEnabled && (
              <Button
                fullWidth
                i18nKey="temporary:otpLogin.buttons.codeByEmail"
                variant="outlined"
                size="large"
                onClick={() => {
                  setInitialDeliveryType?.('email');
                  submitForm();
                }}
                sx={{ mt: 2 }}
              >
                Send Code By Email
              </Button>
            )}
            <Typography
              i18nKey="temporary:otpLogin.nonCached.termsAndPrivacy"
              i18nComponents={{
                termsLink,
                privacyLink,
              }}
              variant="body2"
              textAlign="center"
              mt={4}
              width="55%"
            >
              {`By continuing, you agree to our <termsLink>terms of service</termsLink> and <privacyLink>privacy policy</privacyLink>.`}
            </Typography>
          </Box>
        </Box>
        <SupportCard emailSubject="[Support Request] Faeth App Login" />
      </Box>
    </ImageBasePage>
  );
};

export default OtpLogin;
