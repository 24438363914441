import React, { FC } from 'react';
import {
  Appointment,
  AppointmentDuration,
  AppointmentInput,
  AppointmentLocation,
  useAddAppointmentMutation,
  useGetProfileQuery,
  useUnscheduleAppointmentMutation,
  useUpdateAppointmentMutation,
} from '@fdha/graphql-api-patient';
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  useTheme,
} from '@mui/material';
import { Form, Formik } from 'formik';
import {
  ActivityFormActions,
  ApptSchema,
  apptValidationSchema,
  useDialog,
  useSnackbar,
  Icon,
  ActivityKind,
  Typography,
} from '@fdha/web-ui-library';
import { ApolloCache } from '@apollo/client';
import { useAnalytics, useTranslation } from '@fdha/common-hooks';
import { getTranslatedErrorMessage } from '@fdha/common-utils';

import ActivityFormFields from '../Forms/ActivityFormFields';
import { buildInitialPayload, getSchemaInitialValues } from '../Forms/schema';

interface Props {
  data?: Appointment;
  onCancel: () => void;
}

const buildApptPayload = (values: ApptSchema): AppointmentInput => {
  return {
    ...buildInitialPayload(values),
    ends: values.ends,
    duration: values.duration,
    location: values.location,
    hasVideoCall: values.hasVideoCall,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    syncWithGoogle: !!values.syncWithGoogle,
  };
};

const AddOrEditAppt: FC<Props> = ({ data, onCancel }) => {
  const requiredMessage = getTranslatedErrorMessage('required', 'web');
  const validDateMessage = getTranslatedErrorMessage('validDate', 'web');
  const validTimeMessage = getTranslatedErrorMessage('validTime', 'web');
  const endDateAfterStartMessage = getTranslatedErrorMessage(
    'endDateAfterStartDate',
    'web'
  );
  const endDateFutureMessage = getTranslatedErrorMessage(
    'endDateFuture',
    'web'
  );

  const isEditing = !!data;
  const [addAppointment] = useAddAppointmentMutation();
  const [unscheduleAppointment] = useUnscheduleAppointmentMutation();
  const [updateAppointment] = useUpdateAppointmentMutation();
  const { data: profileData, refetch } = useGetProfileQuery();
  const { showSnackbarV2 } = useSnackbar();
  const theme = useTheme();
  const { closeDialog } = useDialog();
  const { analyticsClient } = useAnalytics();
  const { translate } = useTranslation();

  const initialValues: ApptSchema = {
    ...getSchemaInitialValues(data),
    duration: data?.duration ?? AppointmentDuration.FifteenMinutes,
    location: data?.location ?? AppointmentLocation.InPerson,
    hasVideoCall: !!data?.hasVideoCall,
    syncWithGoogle: !!data?.google_event_id,
  };

  const updateCache = (cache: ApolloCache<any>) => {
    cache.evict({ fieldName: 'activitiesInRange' });
    cache.gc();
  };

  const saveAppt = async (values: ApptSchema) => {
    const payload = buildApptPayload(values);
    if (data) {
      try {
        await updateAppointment({
          variables: { id: data.id, props: payload },
          update(cache) {
            updateCache(cache);
          },
        });
        showSnackbarV2({
          message: 'Changes Saved',
          severity: 'success',
          i18nKey: 'common:snackbar.changesSaved',
        });
      } catch (error) {
        showSnackbarV2({
          message: 'Error to Update Appointment',
          severity: 'error',
          i18nKey: 'activities:addOrEdit.snackbar.appointment.errorUpdate',
        });
      }
    } else {
      try {
        const createdAppointment = await addAppointment({
          variables: { props: payload },
          update(cache) {
            updateCache(cache);
          },
        });
        analyticsClient?.track('Activity Created', {
          id: createdAppointment.data?.addAppointment.id,
          type: ActivityKind.Appointment,
        });
        showSnackbarV2({
          message: 'Appointment Created',
          severity: 'success',
          i18nKey: 'activities:addOrEdit.snackbar.appointment.created',
        });
      } catch (error) {
        showSnackbarV2({
          message: 'Error to Create Appointment',
          severity: 'error',
          i18nKey: 'activities:addOrEdit.snackbar.appointment.errorCreate',
        });
      }
    }
    closeDialog();
  };

  const handleUnschedule = async () => {
    if (!data) {
      return;
    }

    try {
      await unscheduleAppointment({
        variables: { id: data.id },
        update(cache) {
          updateCache(cache);
        },
      });
      showSnackbarV2({
        message: 'Appointment Unscheduled',
        severity: 'success',
        i18nKey: 'activities:addOrEdit.snackbar.appointment.unscheduled',
      });
    } catch (error) {
      showSnackbarV2({
        message: 'Unable to Unschedule Appointment',
        severity: 'error',
        i18nKey: 'activities:addOrEdit.snackbar.appointment.unableUnschedule',
      });
    }
    closeDialog();
  };

  const handleGoogleOAuthClick = () => {
    const newUrl = '/google-oauth';
    const newWindow = window.open(
      newUrl,
      'Google Authentication',
      'height=600,width=500'
    );

    if (newWindow?.focus) {
      newWindow.focus();

      window.addEventListener('locationchange', () => {
        const query = new URLSearchParams(window.location.search);
        const googleOAuth = query.get('google_oauth_success');

        if (googleOAuth === '1') {
          showSnackbarV2({
            message: 'Google credentials saved with success!',
            severity: 'success',
            i18nKey: 'activities:addOrEdit.snackbar.googleCredentials.saved',
          });
          refetch();
        } else if (googleOAuth === '0') {
          showSnackbarV2({
            message: 'Error to save Google Credentials',
            severity: 'error',
            i18nKey: 'activities:addOrEdit.snackbar.googleCredentials.error',
          });
        }
      });
    }
  };

  const userHasGoogleAuth = (): boolean => !!profileData?.me.gapi_refresh_token;

  const isCheckboxDisabled = (): boolean =>
    !userHasGoogleAuth() || !!data?.google_event_id;

  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnChange
        validationSchema={apptValidationSchema({
          required: requiredMessage,
          date: validDateMessage,
          time: validTimeMessage,
          future: endDateFutureMessage,
          afterStart: endDateAfterStartMessage,
        })}
        onSubmit={saveAppt}
      >
        {({ handleChange, values, isSubmitting }) => {
          return (
            <Form>
              <ActivityFormFields>
                <ActivityFormFields.Title />
                <ActivityFormFields.Details />
                <ActivityFormFields.DateInput />
                <ActivityFormFields.EndsAt />
                <Stack
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  columnGap={2}
                >
                  <ActivityFormFields.Time />
                  <ActivityFormFields.Duration />
                </Stack>
                <ActivityFormFields.Frequency />
                <ActivityFormFields.Location />
                <ActivityFormFields.Reminder />
              </ActivityFormFields>
              <Box display="flex" justifyContent="end" mt={2}>
                <FormControlLabel
                  sx={{
                    marginRight: 1,
                  }}
                  control={
                    <Checkbox
                      name="syncWithGoogle"
                      onChange={handleChange}
                      disabled={isCheckboxDisabled()}
                      checked={values.syncWithGoogle}
                    />
                  }
                  label={
                    <Typography i18nKey="activities:addOrEdit.calendar.label">
                      Sync with my Google Calendar
                    </Typography>
                  }
                  title={
                    !userHasGoogleAuth()
                      ? translate(
                          'activities:addOrEdit.calendar.title',
                          'Click on the icon beside and sign in with your Google Account'
                        )
                      : ''
                  }
                />
                {!userHasGoogleAuth() && (
                  <Box mt={0.4}>
                    <IconButton
                      onClick={handleGoogleOAuthClick}
                      title={translate(
                        'activities:addOrEdit.calendar.button',
                        'Open Google sign in'
                      )}
                    >
                      <Icon
                        name="external-link-outline"
                        size="medium"
                        fill={theme.palette.text.secondary}
                      />
                    </IconButton>
                  </Box>
                )}
              </Box>
              <ActivityFormActions
                isEditing={isEditing}
                onUnschedule={handleUnschedule}
                disableSave={isSubmitting}
                onCancel={onCancel}
              />
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AddOrEditAppt;
