import { useEffectOnce } from '@fdha/common-hooks';
import React, { useEffect, useState } from 'react';

import { Typography } from '..';
export interface CountdownProps {
  timeInSeconds: number;
  onTimeReached?: () => void;
}

export const Countdown: React.FC<CountdownProps> = ({
  timeInSeconds,
  onTimeReached,
}) => {
  const [timeRemaining, setTimeRemaining] = useState(timeInSeconds);

  useEffect(() => {
    if (timeRemaining === 0) {
      onTimeReached?.();
    }
  }, [onTimeReached, timeRemaining]);

  useEffectOnce(() => {
    const timerInterval = setInterval(() => {
      setTimeRemaining((prevTime) => {
        if (prevTime === 0) {
          clearInterval(timerInterval);
          return 0;
        }

        return prevTime - 1;
      });
    }, 1000);
  });

  return (
    <Typography
      textAlign="center"
      i18nKey="temporary:otpLogin.code.tryAgain"
      i18nParams={{ seconds: timeRemaining }}
    >
      {`Try again in ${timeRemaining}s`}
    </Typography>
  );
};
