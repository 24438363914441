import React from 'react';
import { DefaultBasePage } from '@components';
import { getTranslatedErrorMessage } from '@fdha/common-utils';
import {
  addTimeInTheFutureValidation,
  Button,
  decimalValidation,
  EmptyStateCard,
  formatUTCDate,
  getAddressValidation,
  getBhbValidation,
  Loader,
  NavigationCloseAction,
  useDialog,
  useSnackbar,
} from '@fdha/web-ui-library';
import { QuestionType, FormStatus, DecimalQuestionProps } from '@models';
import { Box } from '@mui/material';
import { checkLogicCondition, SurveyView } from '@utils';
import { isValid } from 'date-fns';
import { Form, Formik, FormikTouched } from 'formik';
import { FC, useEffect, useMemo, useState } from 'react';
import * as Yup from 'yup';
import { isEqual } from 'lodash';

import StepsForm from './StepsForm';

export interface SurveyData {
  title?: string;
  i18nKeyTitle?: string;
  i18nKeySubtitle?: string;
  i18nSubtitleParams?: { [key: string]: string };
  date?: number;
}

interface StepsProps {
  surveyData: SurveyData;
  views?: SurveyView[];
  loading?: boolean;
  handleSubmit: (values: any) => void;
}

const Steps: FC<StepsProps> = ({
  surveyData,
  views,
  loading,
  handleSubmit,
}) => {
  const requiredMessage = getTranslatedErrorMessage('required', 'web');
  const validTimeMessage = getTranslatedErrorMessage('validTime', 'web');
  const timeNotInFutureMessage = getTranslatedErrorMessage(
    'timeNotInFuture',
    'web'
  );
  const decimalMessage = getTranslatedErrorMessage('validDecimal', 'web');
  const bhbMessage = getTranslatedErrorMessage('validBhb', 'web');
  const validDateMessage = getTranslatedErrorMessage('validDate', 'web');
  const validZipMessage = getTranslatedErrorMessage('validZip', 'web');

  const { showSnackbarV2 } = useSnackbar();
  const { openDialogV2, closeDialog } = useDialog();

  // views updated according to answers. Necessary for progress bar and confirmation view to only
  // show questions that are not skipped
  const [currentAvailableViews, setCurrentAvailableViews] = useState<
    SurveyView[]
  >([]);

  // Initializing currentAvailableViews and viewId after loading is done
  useEffect(() => {
    if (!loading) {
      if (views?.length && currentAvailableViews.length === 0) {
        setCurrentAvailableViews([...views]);
      }
    }
  }, [currentAvailableViews.length, loading, views]);

  const [status, setStatus] = useState(FormStatus.NOT_CHANGED);
  const [progress, setProgress] = useState(0);
  const [index, setIndex] = useState(0);
  const [openedScreens, setOpenedScreens] = useState<string[]>([]);

  const view = currentAvailableViews[index];

  const isRequired = view?.type === 'question' && view.viewProps.required;

  const isLastScreen = progress === 100;
  const isFirstScreen = index === 0;

  const title = useMemo(() => {
    if (!view?.showTitle) {
      return undefined;
    }
    return surveyData.title;
  }, [surveyData.title, view?.showTitle]);

  const isoDate = useMemo(() => {
    if (!view?.showDate) {
      return undefined;
    }

    if (!surveyData.date) {
      return undefined;
    }

    return new Date(surveyData.date).toISOString();
  }, [surveyData.date, view?.showDate]);

  const subtitle = formatUTCDate(isoDate, 'monthWeekDay');

  useEffect(() => {
    if (currentAvailableViews.length) {
      const progress = ((index + 1) / currentAvailableViews.length) * 100;

      setProgress(Math.min(progress, 100));
    }
  }, [index, currentAvailableViews]);

  const handleFinish = (
    values: any,
    setSubmitting: (value: boolean) => void
  ) => {
    if (values.bhb) {
      setSubmitting(false);
    }
    handleSubmit(values);
    return index;
  };

  const handleNext = (
    values: any,
    setTouched: (touched: FormikTouched<any>) => void,
    setFieldValue: (key: string, value: any) => void,
    setSubmitting: (value: boolean) => void
  ) => {
    let newCurrentAvailableViews = [...(views || [])];
    let newValues = values;

    const newOpenedScreens = [...openedScreens, view?.viewProps.id];
    setOpenedScreens(newOpenedScreens);

    views?.forEach((view) => {
      const shouldSkip =
        view.type === 'question'
          ? checkLogicCondition(view.viewProps, values, newOpenedScreens)
          : undefined;

      const viewIndex = newCurrentAvailableViews.findIndex(
        (availableView) => availableView.viewProps.id === view.viewProps.id
      );

      if (shouldSkip) {
        // Set skipped view initial value again
        const key = view.viewProps.id;
        const initialValue = getInitialValues()[key];
        newValues[key] = initialValue;
        setFieldValue(key, initialValue);

        if (viewIndex !== -1) {
          newCurrentAvailableViews.splice(viewIndex, 1);
        }
      }
    });

    const currentViewIndex = newCurrentAvailableViews.findIndex(
      (availableView) => {
        return availableView.viewProps.id === view?.viewProps.id;
      }
    );

    if (currentViewIndex + 1 > newCurrentAvailableViews.length - 1) {
      handleFinish(newValues, setSubmitting);
    } else {
      if (!isEqual(newCurrentAvailableViews, currentAvailableViews)) {
        setCurrentAvailableViews([...newCurrentAvailableViews]);
      }
      setSubmitting(false);
      setTouched({});
      setIndex(currentViewIndex + 1);
    }
  };

  const handlePrevious = () => {
    setIndex(index - 1);
    setOpenedScreens(openedScreens.slice(0, -1));
  };

  const getInitialValues = () => {
    let initialValues: any = {};

    views?.forEach((view) => {
      if (view.type === 'question') {
        const question = view.viewProps;

        const value = question.initialValue || '';

        switch (question.type) {
          case QuestionType.Address:
            initialValues[question.id] = {
              address: '',
              complement: '',
              city: '',
              state: '',
              zip: '',
            };
            break;
          case QuestionType.MultipleChoice:
            initialValues[question.id] = [];
            break;
          case QuestionType.SingleChoice:
            initialValues[question.id] = { option: '', otherOption: '' };
            break;
          case QuestionType.Time: {
            initialValues[question.id] = { time: '', dayPeriod: 'am' };
            break;
          }
          case QuestionType.Meal:
          case QuestionType.Snack: {
            initialValues[question.id] = { dishOption: '', linkOption: '' };
            break;
          }
          case QuestionType.Decimal: {
            initialValues[question.id] = { decimal: '', unit: '' };
            break;
          }
          default:
            initialValues[question.id] = value;
            break;
        }
      }
    });

    return initialValues;
  };

  const getCoverImage = () => {
    if (view?.type === 'explanation' && view.viewProps.headerAsset) {
      return view.viewProps.headerAsset;
    }
  };

  const showError = (
    error: string,
    i18nKey: string = 'surveys:snackbar.selectOneOption'
  ) => {
    showSnackbarV2({
      severity: 'error',
      message: error,
      closeOnClickOutside: true,
      i18nKey: i18nKey,
    });
  };

  const validate = (values: any) => {
    if (view?.type !== 'question') {
      return;
    }

    const errors: Partial<any> = {};
    const value = values[view.viewProps?.id];

    switch (view.viewProps.type) {
      case QuestionType.MultipleChoice:
        if (!value?.length && isRequired) {
          errors[view.viewProps.id] = 'Please select at least one option';
          showError(
            errors[view.viewProps.id],
            'surveys:snackbar.selectAtLeastOneOption'
          );
        }
        break;
      case QuestionType.SingleChoice:
        if (isRequired) {
          if (value.option === '') {
            errors[view.viewProps.id] = 'Please select one option';
            showError(errors[view.viewProps.id]);
          } else if (value.option === 'other' && !value.otherOption) {
            errors[view.viewProps.id] = { otherOption: requiredMessage };
          }
        }
        break;
      case QuestionType.Scale:
      case QuestionType.Binary:
        if (value === '' && isRequired) {
          errors[view.viewProps.id] = 'Please select one option';
          showError(errors[view.viewProps.id]);
        }
        break;
      case QuestionType.DateOption:
        if (value.radioOption === '' && isRequired) {
          errors[view.viewProps.id] = 'Please select one option';
          showError(errors[view.viewProps.id]);
        } else if (value.radioOption && value.radioOption !== 'dontRemember') {
          if (!isValid(value.date)) {
            errors[view.viewProps.id] = {
              date: validDateMessage,
            };
          }
        }
        break;
      default:
        break;
    }

    return errors;
  };

  const getValidation = (validation?: Yup.AnySchema) => {
    if (!view || !validation) {
      return;
    }

    return Yup.object().shape({
      [view.viewProps?.id]: validation,
    });
  };

  const stringValidation = isRequired
    ? Yup.string().trim().required(requiredMessage)
    : Yup.string();

  const dishValidation = Yup.object().shape(
    {
      dishOption: Yup.string().when(['linkOption'], {
        is: (linkOption: string) => !linkOption,
        then: stringValidation,
      }),
      linkOption: Yup.string().when(['dishOption'], {
        is: (dishOption: string) => !dishOption,
        then: stringValidation,
      }),
    },
    [['dishOption', 'linkOption']]
  );

  const getDecimalValidation = () => {
    if (!view) {
      return;
    }

    const { customPropsOfType } = view.viewProps as DecimalQuestionProps;

    return decimalValidation(
      customPropsOfType?.min,
      customPropsOfType?.max,
      decimalMessage,
      !!isRequired
    );
  };

  const validationSchemaByQuestionType: Record<any, Yup.AnySchema | undefined> =
    {
      [QuestionType.Address]: getValidation(
        getAddressValidation({
          required: requiredMessage,
          zip: validZipMessage,
        })
      ),
      [QuestionType.TextSelect]: getValidation(
        Yup.object().shape({
          text: stringValidation,
          select: stringValidation,
        })
      ),
      [QuestionType.Autocomplete]: getValidation(stringValidation),
      [QuestionType.Number]: getValidation(
        isRequired ? Yup.number().required(requiredMessage) : Yup.number()
      ),
      [QuestionType.OpenText]: getValidation(stringValidation),
      [QuestionType.BHB]: getValidation(
        getBhbValidation({
          future: timeNotInFutureMessage,
          valid: validTimeMessage,
          required: requiredMessage,
          bhb: bhbMessage,
        })
      ),
      [QuestionType.Time]: getValidation(
        Yup.object().shape({
          time: addTimeInTheFutureValidation({
            valid: validTimeMessage,
            future: timeNotInFutureMessage,
          }),
          dayPeriod: stringValidation,
        })
      ),
      [QuestionType.Meal]: getValidation(dishValidation),
      [QuestionType.Snack]: getValidation(dishValidation),
      [QuestionType.Decimal]: getValidation(getDecimalValidation()),
    };

  const getValidationSchema = () => {
    if (view?.type !== 'question') {
      return null;
    }

    return validationSchemaByQuestionType[view.viewProps.type];
  };

  const maybeShowDialog = async (): Promise<NavigationCloseAction> => {
    return new Promise((resolve) => {
      if (status === FormStatus.CHANGED) {
        openDialogV2({
          title: 'Are you sure you want to close?',
          content: 'Your progress will not be saved.',
          confirmButtonLabel: 'Close',
          cancelButtonLabel: 'Stay',
          i18nKeyTitle: 'surveys:leaveDialog.title',
          i18nKeyContent: 'surveys:leaveDialog.content',
          handleConfirm: async () => {
            closeDialog();
            resolve('close');
          },
          handleCancel: () => {
            resolve('cancel');
          },
        });
      } else {
        resolve('close');
      }
    });
  };

  return (
    <DefaultBasePage
      showClose
      contentSize="small"
      showBack={false}
      showNavigation={false}
      coverImageUrl={getCoverImage()}
      handleActionBeforeClose={maybeShowDialog}
      title={title}
      subtitle={subtitle}
      i18nKeyTitle={surveyData?.i18nKeyTitle}
      i18nKeySubtitle={surveyData?.i18nKeySubtitle}
      i18nSubtitleParams={{ date: isoDate || '' }}
      progress={{
        show: !!view?.showProgress,
        progressBarProps: {
          completed: progress,
          type: view?.hideProgressLabel ? 'noLabel' : 'percentage',
        },
      }}
      isLoading={loading}
    >
      {loading ? (
        <Loader />
      ) : currentAvailableViews?.length ? (
        <>
          <Formik
            initialValues={getInitialValues()}
            onSubmit={(
              values,
              { setTouched, setFieldValue, setSubmitting }
            ) => {
              handleNext(values, setTouched, setFieldValue, setSubmitting);
            }}
            validationSchema={getValidationSchema()}
            validate={validate}
          >
            {({
              values,
              setTouched,
              setFieldValue,
              setSubmitting,
              isSubmitting,
            }) => (
              <Form data-testid="STEPS_FORM">
                <StepsForm
                  values={values}
                  views={currentAvailableViews}
                  view={currentAvailableViews[index]}
                  handleNext={() =>
                    handleNext(values, setTouched, setFieldValue, setSubmitting)
                  }
                  setStatus={setStatus}
                />
                <Box display="flex" justifyContent="flex-end" mt={3} mb={4}>
                  {!isFirstScreen && (
                    <Button
                      variant="outlined"
                      sx={{ mr: 2, width: '135px' }}
                      onClick={handlePrevious}
                      data-testid="PREVIOUS_BUTTON"
                      i18nKey="common:button.previous"
                    >
                      Previous
                    </Button>
                  )}
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{ width: '135px' }}
                    disabled={isSubmitting}
                    data-testid={`BUTTON_${
                      isLastScreen ? 'SUBMIT_SURVEY' : 'NEXT'
                    }`}
                    i18nKey={
                      isLastScreen
                        ? 'common:button.submitSurvey'
                        : 'common:button.next'
                    }
                  >
                    {isLastScreen ? 'Submit Survey' : 'Next'}
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </>
      ) : (
        <EmptyStateCard
          message="Survey not found"
          i18nKeyMessage="surveys:emptyState.notFound"
        />
      )}
    </DefaultBasePage>
  );
};

export default Steps;
