import React, { useState } from 'react';
import { OtpCode, OtpLogin } from '@pages';

import { LoginForm } from '../login/OtpLogin';
import { DeliveryType } from '../code/OtpCode';

const OtpFlow: React.FC = () => {
  const [step, setStep] = useState<'login' | 'otp'>('login');
  const [initialDeliveryType, setInitialDeliveryType] =
    useState<DeliveryType>('phone');
  const [deliveryData, setDeliveryData] = useState({
    inputType: '',
    value: '',
  });

  const handleLoginSubmit = (values: LoginForm) => {
    setDeliveryData({
      inputType: values.inputType,
      value: values[values.inputType as keyof LoginForm],
    });
    setStep('otp');
  };

  return (
    <>
      {step === 'login' ? (
        <OtpLogin
          onSubmit={handleLoginSubmit}
          setInitialDeliveryType={setInitialDeliveryType}
        />
      ) : (
        <OtpCode
          initialDeliveryType={initialDeliveryType}
          deliveryData={deliveryData}
          setStep={setStep}
        />
      )}
    </>
  );
};

export default OtpFlow;
