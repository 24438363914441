import { Typography, VariantType } from '@fdha/web-ui-library';
import { Box, useTheme } from '@mui/material';
import React, { PropsWithChildren } from 'react';

import { ContentSize, contentSizes, HORIZONTAL_PADDING } from './utils';

const CONTENT_MINIMUM_SIZE = 600;
const IMAGE_MINIMUM_SIZE = 465;
const BREAKPOINT = CONTENT_MINIMUM_SIZE + IMAGE_MINIMUM_SIZE;

export interface ImageProps {
  url: string;
  altText: string;
  background?: string;
}

interface Props {
  image: ImageProps;
  title?: React.ReactNode;
  i18nKeyTitle?: string;
  titleVariant?: VariantType;
  subtitle?: React.ReactNode;
  i18nKeySubtitle?: string;
  i18nParamsSubtitle?: Record<string, string>;
  contentSize?: ContentSize;
  showLogo?: boolean;
  alignHeader?: 'center' | 'flex-start';
  imagePosition?: 'left' | 'right';
  fullImage?: boolean;
  alignSubtitle?: 'center' | 'end' | 'inherit';
}

const ImageBasePage: React.FC<PropsWithChildren<Props>> = ({
  image,
  title,
  i18nKeyTitle,
  titleVariant = 'h4',
  alignHeader = 'center',
  subtitle,
  i18nKeySubtitle,
  i18nParamsSubtitle,
  alignSubtitle,
  contentSize = 'xsmall',
  showLogo = true,
  imagePosition = 'left',
  fullImage,
  children,
}) => {
  const theme = useTheme();

  const size = contentSizes[contentSize];
  const imageBackground = image.background || theme.palette.background.bright4;
  const shouldRenderTopContent = showLogo || !!title || !!subtitle;
  const isImageLeft = imagePosition === 'left';

  const renderTitle = () => {
    if (typeof title === 'string') {
      return (
        <Typography
          i18nKey={i18nKeyTitle}
          data-testid="TITLE_RESET_PASSWORD"
          variant={titleVariant}
        >
          {title}
        </Typography>
      );
    }

    return title;
  };

  const renderSubtitle = () => {
    if (typeof subtitle === 'string') {
      return (
        <Typography
          i18nKey={i18nKeySubtitle}
          data-testid="SUBTITLE_RESET_PASSWORD"
          variant="h6"
          textAlign={alignSubtitle}
          i18nParams={i18nParamsSubtitle}
          color={theme.palette.text.secondary}
        >
          {subtitle}
        </Typography>
      );
    }

    return subtitle;
  };

  const renderTopContent = () => {
    return (
      <Box
        display="flex"
        flexDirection="column"
        alignItems={alignHeader}
        marginBottom={4}
      >
        {showLogo && (
          <img
            style={{ marginBottom: theme.spacing(4) }}
            src="/images/faeth_logo_medium.png"
            alt="Faeth logo"
          />
        )}
        {title && renderTitle()}
        {subtitle && renderSubtitle()}
      </Box>
    );
  };

  const renderImage = () => (
    <Box
      flex={1}
      display="flex"
      alignItems="flex-end"
      sx={{
        [theme.breakpoints.down(BREAKPOINT)]: {
          display: 'none',
        },
        backgroundColor: imageBackground,
      }}
      minWidth={IMAGE_MINIMUM_SIZE}
    >
      <img
        src={image.url}
        alt={image.altText}
        style={{ objectFit: 'cover' }}
        height={fullImage ? '100%' : undefined}
        width="100%"
        data-testid="RENDER_IMAGE"
      />
    </Box>
  );

  return (
    <Box display="flex" height="100vh" overflow="hidden">
      <Box display="flex" width="100%" minHeight="100%">
        <Box overflow="auto" width="100%">
          <Box
            display="flex"
            height="100%"
            minWidth={theme.breakpoints.values.sm}
          >
            {isImageLeft && renderImage()}
            <Box
              flex={2}
              sx={{
                [theme.breakpoints.up(BREAKPOINT)]: {
                  overflow: 'auto',
                },
                direction: isImageLeft ? 'ltr' : 'rtl',
              }}
            >
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                minHeight="100%"
                sx={{ direction: 'ltr' }}
              >
                <Box
                  width="100%"
                  maxWidth={size}
                  marginX="auto"
                  paddingX={`${HORIZONTAL_PADDING}px`}
                  paddingY={3}
                >
                  {shouldRenderTopContent && renderTopContent()}
                  <Box>{children}</Box>
                </Box>
              </Box>
            </Box>
            {!isImageLeft && renderImage()}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ImageBasePage;
